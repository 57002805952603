import { render, staticRenderFns } from "./BaseBreadcrumbs.vue?vue&type=template&id=ea2af376&scoped=true&"
import script from "./BaseBreadcrumbs.vue?vue&type=script&lang=js&"
export * from "./BaseBreadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./BaseBreadcrumbs.vue?vue&type=style&index=0&id=ea2af376&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea2af376",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default})
